<template>
  <SideModal
    :show="showNotesModal"
    @exit="$emit('update:showNotesModal', false)"
    body-class="notes-body"
    title="Notes"
    :z-index="9999"
    :show-header="false"
    v-click-outside="config"
  >
    <template #body>
      <div class="pt-3 px-3">
        <div
          class="py-5 d-flex justify-content-between align-items-center mb-5"
        >
          <p class="text-primary mb-0" v-if="currentDoctor.id">Notes</p>
          <p class="text-primary mb-0" v-else>Add Notes</p>
          <a
            href="#"
            @click="$emit('update:showNotesModal', false)"
            class="text-uppercase"
            style="letter-spacing: 0.1rem"
          >
            Close
          </a>
        </div>
        <div class="mb-4" v-if="!currentDoctor.id">
          <ValidationObserver ref="notesForm" v-slot="{ handleSubmit }">
            <small class="cancel-field-label"> Notes </small>
            <ValidationProvider
              name="Notes"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-textarea
                id="textarea"
                class="px-0"
                v-model="notes"
                rows="6"
                max-rows="8"
                placeholder="Write your notes here"
              ></b-form-textarea>
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
            <b-row class="mt-4">
              <b-col cols="6">
                <b-button
                  pill
                  variant="link"
                  block
                  class="text-primary"
                  @click="clearText"
                >
                  Clear
                </b-button>
              </b-col>
              <b-col cols="6">
                <b-button
                  pill
                  variant="primary"
                  block
                  @click="handleSubmit(addNote)"
                  :disabled="isProcessing"
                >
                  <b-spinner v-if="isProcessing" small></b-spinner>
                  <span v-else>Save</span>
                </b-button>
              </b-col>
            </b-row>
          </ValidationObserver>
        </div>
        <div
          v-if="notesList.length > 0"
          class="notes-list-container"
          style="max-height: calc(100vh - 450px); overflow: scroll"
        >
          <div
            class="py-3"
            :class="{ 'notes-border': idx !== notesList.length - 1 }"
            v-for="(noteInfo, idx) in notesList"
            :key="noteInfo.id"
          >
            <p class="mb-1">{{ noteInfo.notes }}</p>
            <small style="color: #333333">
              {{ parseDate(noteInfo.timestampcreated) }} by Dr.
              {{ noteInfo.doctor }}
            </small>
          </div>
        </div>
        <div class="notes-list-container text-center" v-else>
          Nothing to see here...
        </div>
      </div>
    </template>
  </SideModal>
</template>

<script>
import SideModal from "@/components/partials/SideModal";
import { format, fromUnixTime } from "date-fns";
import vClickOutside from "v-click-outside";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  props: {
    showNotesModal: {
      type: Boolean,
      default: false,
    },
    patientId: {
      type: String,
      default: "",
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    SideModal,
  },
  data() {
    return {
      notes: "",
      isProcessing: false,
      vcoConfig: {
        handler: this.onClickOutside,
        isActive: false,
      },
    };
  },
  computed: {
    notesList() {
      return this.$store.state.notes.notesList;
    },
    isAddNotesModalOpen() {
      return this.$store.state.globals.isAddNotesModalOpen;
    },
    config() {
      return { ...this.vcoConfig };
    },

    currentDoctor() {
      return this.$store.state.globals.currentDoctor;
    },
  },
  watch: {
    showNotesModal(val) {
      if (val) {
        if (this.$route.name === "messages" && this.patientId) {
          this.$store.dispatch("notes/fetchPatientNotesList", {
            patientid: this.patientId,
          });
        }

        if (this.$route.name !== "messages") {
          this.$store.dispatch("notes/fetchPatientNotesList", {
            patientid: this.$route.params.id,
          });
        }
      }
    },
    isAddNotesModalOpen(val) {
      if (val) {
        this.vcoConfig.isActive = true;
        this.$emit("update:showNotesModal", true);
      } else {
        this.vcoConfig.isActive = false;
        this.$store.dispatch("notes/clearNotesList");
      }
    },
    patientId(val) {
      if (val && this.showNotesModal) {
        this.$store.dispatch("notes/fetchPatientNotesList", {
          patientid: val,
        });
      }
    },
  },
  methods: {
    parseDate(date) {
      return format(fromUnixTime(date), "MM/dd/yyyy hh:mmaaa");
    },
    async addNote() {
      try {
        this.isProcessing = true;
        await this.$store.dispatch("notes/addNote", {
          patientid: this.patientId ? this.patientId : this.$route.params.id,
          notes: this.notes,
        });
        this.notes = "";
      } catch (e) {
        console.error(e);
      } finally {
        this.isProcessing = false;
        this.$nextTick(() => {
          this.$refs.notesForm.reset();
        });
      }
    },
    clearText() {
      this.notes = "";
    },
    onClickOutside() {
      this.$emit("update:showNotesModal", false);
    },
  },
  destroyed() {
    this.$store.dispatch("notes/clearNotesList");
    this.$emit("update:showNotesModal", false);
  },
};
</script>

<style scoped>
.notes-list-container {
  background: #f9fbfb;
  padding: 0.5rem 1.5rem;
}

.notes-border {
  border-bottom: 1px solid #e4e4e4;
}
</style>
