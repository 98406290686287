<template>
  <div
    class="shadow-sm"
    :class="[
      'custom-modal',
      show ? 'show-modal' : '',
      side == 'left' ? 'left' : '',
    ]"
    :style="{ 'z-index': zIndex }"
    ref="sideModal"
  >
    <b-row
      class="px-4 py-4"
      :class="['custom-header', headerClass]"
      ref="modalHeader"
      v-show="showHeader"
    >
      <b-col align-self="center">
        <slot name="header">
          <h2 class="mb-0">{{ title }}</h2>
        </slot>
      </b-col>
      <b-col cols="auto">
        <b-icon
          v-if="showClose"
          class="close-icon"
          @click="close"
          icon="x-circle-fill"
          variant="primary"
        ></b-icon>
      </b-col>
    </b-row>
    <div :class="['custom-body', bodyClass]" :style="{ height: height }">
      <slot name="body"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    headerClass: {
      type: String,
    },
    bodyClass: {
      type: String,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "",
    },
    zIndex: {
      type: Number,
      default: 600,
    },
    side: {
      type: String,
      default: "right",
    },
  },
  data() {
    return {
      height: "",
    };
  },
  watch: {
    show(val) {
      if (this.title === "Notes") {
        this.$store.commit("globals/SET_ADD_NOTES_MODAL_STATE", val);
      }
    },
  },
  created() {
    addEventListener("popstate", this.popStateHandler);
  },
  methods: {
    close(event) {
      this.$emit("exit", event);
    },
    observeHeight() {
      let modal = this.$refs.sideModal.clientHeight;
      let header = this.$refs.modalHeader.clientHeight;
      this.height = modal - header - 80 + "px";
    },
    popStateHandler(event) {
      if (this.$store.state.globals.isAddNotesModalOpen) {
        this.close(event);
      }
    },
  },
  mounted() {
    this.observeHeight();
  },
  destroyed() {
    removeEventListener("popstate", this.popStateHandler);
  },
};
</script>

<style lang="scss" scoped>
$width: 30%;

.custom-modal {
  position: fixed;
  width: $width;
  height: 100%;
  top: 70px;
  right: 0;
  bottom: 0;
  transform: translateX(100%);
  z-index: 600;
  background-color: $white;
  transition: transform 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition-delay: 0.15s;

  &.left {
    transform: translateX(0);
    z-index: 900 !important;
    left: -$width !important;
    right: auto !important;

    &.show-modal {
      transform: translateX(100%);
    }
  }

  &.show-modal {
    transform: translateX(0);
  }

  @include media-breakpoint-down(lg) {
    width: 100%;

    &.left {
      left: -100% !important;
    }
  }

  @include media-breakpoint-up(md) {
    top: 0;
  }

  .custom-header {
    position: relative;

    &.terms-title {
      justify-content: flex-start;
      padding: 15px 2rem;
    }

    &.canned-reply-header {
      margin-top: 83px;

      @include media-breakpoint-down(md) {
        margin-top: 0;
      }

      @include media-breakpoint-up(xl) {
        font-size: 1.5rem;
        margin-top: 55px;
      }
    }

    .close-icon {
      // position: absolute;
      // top: 1rem;
      // right: 1rem;
      cursor: pointer;

      @include media-breakpoint-down(sm) {
        font-size: 1.5rem;
      }

      @include media-breakpoint-up(xxl) {
        font-size: 1.5rem;
      }
    }
  }

  .custom-body {
    padding: 0 1rem 0 1rem;

    &.terms-body {
      overflow: auto;
    }

    &.canned-reply-body {
      overflow: auto !important;
      background-color: $white;

      @include media-breakpoint-up(xl) {
        overflow: hidden;
      }
    }
  }
}
</style>
