var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("SideModal", {
    directives: [
      {
        name: "click-outside",
        rawName: "v-click-outside",
        value: _vm.config,
        expression: "config",
      },
    ],
    attrs: {
      show: _vm.showNotesModal,
      "body-class": "notes-body",
      title: "Notes",
      "z-index": 9999,
      "show-header": false,
    },
    on: {
      exit: function ($event) {
        return _vm.$emit("update:showNotesModal", false)
      },
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c("div", { staticClass: "pt-3 px-3" }, [
              _c(
                "div",
                {
                  staticClass:
                    "py-5 d-flex justify-content-between align-items-center mb-5",
                },
                [
                  _vm.currentDoctor.id
                    ? _c("p", { staticClass: "text-primary mb-0" }, [
                        _vm._v("Notes"),
                      ])
                    : _c("p", { staticClass: "text-primary mb-0" }, [
                        _vm._v("Add Notes"),
                      ]),
                  _c(
                    "a",
                    {
                      staticClass: "text-uppercase",
                      staticStyle: { "letter-spacing": "0.1rem" },
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("update:showNotesModal", false)
                        },
                      },
                    },
                    [_vm._v(" Close ")]
                  ),
                ]
              ),
              !_vm.currentDoctor.id
                ? _c(
                    "div",
                    { staticClass: "mb-4" },
                    [
                      _c("ValidationObserver", {
                        ref: "notesForm",
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ handleSubmit }) {
                                return [
                                  _c(
                                    "small",
                                    { staticClass: "cancel-field-label" },
                                    [_vm._v(" Notes ")]
                                  ),
                                  _c("ValidationProvider", {
                                    attrs: { name: "Notes", rules: "required" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c("b-form-textarea", {
                                                staticClass: "px-0",
                                                attrs: {
                                                  id: "textarea",
                                                  rows: "6",
                                                  "max-rows": "8",
                                                  placeholder:
                                                    "Write your notes here",
                                                },
                                                model: {
                                                  value: _vm.notes,
                                                  callback: function ($$v) {
                                                    _vm.notes = $$v
                                                  },
                                                  expression: "notes",
                                                },
                                              }),
                                              _c(
                                                "small",
                                                { staticClass: "text-danger" },
                                                [_vm._v(_vm._s(errors[0]))]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _c(
                                    "b-row",
                                    { staticClass: "mt-4" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass: "text-primary",
                                              attrs: {
                                                pill: "",
                                                variant: "link",
                                                block: "",
                                              },
                                              on: { click: _vm.clearText },
                                            },
                                            [_vm._v(" Clear ")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              attrs: {
                                                pill: "",
                                                variant: "primary",
                                                block: "",
                                                disabled: _vm.isProcessing,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return handleSubmit(
                                                    _vm.addNote
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm.isProcessing
                                                ? _c("b-spinner", {
                                                    attrs: { small: "" },
                                                  })
                                                : _c("span", [_vm._v("Save")]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2047585673
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.notesList.length > 0
                ? _c(
                    "div",
                    {
                      staticClass: "notes-list-container",
                      staticStyle: {
                        "max-height": "calc(100vh - 450px)",
                        overflow: "scroll",
                      },
                    },
                    _vm._l(_vm.notesList, function (noteInfo, idx) {
                      return _c(
                        "div",
                        {
                          key: noteInfo.id,
                          staticClass: "py-3",
                          class: {
                            "notes-border": idx !== _vm.notesList.length - 1,
                          },
                        },
                        [
                          _c("p", { staticClass: "mb-1" }, [
                            _vm._v(_vm._s(noteInfo.notes)),
                          ]),
                          _c("small", { staticStyle: { color: "#333333" } }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.parseDate(noteInfo.timestampcreated)
                                ) +
                                " by Dr. " +
                                _vm._s(noteInfo.doctor) +
                                " "
                            ),
                          ]),
                        ]
                      )
                    }),
                    0
                  )
                : _c(
                    "div",
                    { staticClass: "notes-list-container text-center" },
                    [_vm._v(" Nothing to see here... ")]
                  ),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }